import fetch, { Request } from "cross-fetch";
import Cookies from "js-cookie";
import settings from "../settings";
import { useSession } from "./useSession";
const API_BACKEND_HOSTNAME = settings.API_URL;

const defaultHeaders = {
  "content-type": "application/json; charset=utf-8",
  "Access-Control-Allow-Origin": "*",
  "Content-Security-Policy":
    "frame-ancestors 'self' *.pgachampionship.uat.pga.golf.psdops.com pgachampionship.uat.pga.golf.psdops.com/fantasy pgachampionship.com",
};
const defaultParams = {
  method: "GET",
  headers: defaultHeaders,
};
const staticRouteTest = /^\/api\/static-/;

export default function useApiFetch() {
  const { token } = useSession();
  const cookieToken = Cookies.get(settings.API_COOKIE_NAME);

  function addApiToken(endpoint: RequestInfo, params?: RequestInit) {
    let apiToken;
    if (token) {
      apiToken = token;
    } else {
      apiToken = cookieToken;
    }

    if (apiToken && !staticRouteTest.test(`${endpoint}`)) {
      const headers = Object.assign({ ...defaultHeaders }, params.headers || {});

      // add both API tokens to requests.
      headers["X-Sti-Auth-Token"] = `Bearer ${apiToken}`;
      params.headers = headers;
    }
    return params;
  }

  function fetchApiEndpoint<T>(endpoint: RequestInfo, params?: RequestInit): Promise<T> {
    const newParams = Object.assign({ ...defaultParams }, addApiToken(endpoint, params));
    const req = new Request(`${API_BACKEND_HOSTNAME}${endpoint}`);

    return fetch(req, newParams)
      .then((res) => {
        return res
          .text()
          .then((text) => {
            // to resolve any body-less responses from our API:
            try {
              const data: T | ApiBackendError = JSON.parse(text);
              if (res.status > 400) {
                if ((data as ApiBackendError).errors) {
                  const errorKey = Object.keys((data as ApiBackendError).errors)[0];
                  throw new Error(`${(data as ApiBackendError).errors[errorKey]}`);
                } else {
                  throw new Error(`Error: Something went wrong. Please try again.`);
                }
              }
              return data as T;
            } catch (error) {
              if (!`${res.status}`.startsWith("20")) {
                throw error;
              }
            }
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        throw err;
      });
  }

  return { fetchApiEndpoint };
}

interface ApiBackendError {
  errors: {
    [property: string]: string[];
  };
}
