import React, { FunctionComponent, useState, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components";
import { Button, ButtonGroup } from "../../styles/buttons.styled";
import InputContainer from "../inputs/InputContainer";
import { useModal } from "../Modal";
import { ErrorContainer, GenericFormError } from "../SignIn/SignIn.styled";
import { useTiebreakerMutation } from "src/hooks/useEntryMutation";
import useClientContext, { setCurrentRoster } from "src/hooks/useClientContext";
import useApiFetch from "src/hooks/useApiFetch";
import RosterCompleteModal from "../Roster/PicksModal/RosterCompleteModal";
import { breakpoints } from "../../styles/styleUtils";
import LockedCategory from "../LockedCategory";
import useAnalytics from "../../hooks/useAnalytics";

import { MdOutlineClose } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";

const StyledTiebreaker = styled.div`
  display: flex;
  background: #fff;
  box-shadow: 0px 4px 36px 0px rgba(0, 10, 22, 0.15);
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
  padding: 0 20px;
  position: relative;

  p {
    margin: 0 20px;
  }

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
    text-align: center;
    padding: 20px 0;
  }

  form {
    @media (max-width: ${breakpoints.mobile}) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  a {
    text-decoration: underline;
    color: ${(props) => props.theme.primary};
  }
`;

type TiebreakerInputs = {
  tiebreaker: string;
};

interface TiebreakerProps {
  allPlayersTeedOff?: boolean;
  handleClearCategory?: () => void;
  gameState?: string;
  correctAnswer?: number;
}

const Tiebreaker: FunctionComponent<TiebreakerProps> = ({
  correctAnswer,
  gameState,
  allPlayersTeedOff,
  handleClearCategory,
}) => {
  const { showModal } = useModal();
  const { fetchApiEndpoint } = useApiFetch();
  const [{ entry }, dispatch] = useClientContext();
  const [genericError, setGenericError] = useState<string>("");
  const { dismissModal, modalContent } = useModal();

  const mutation = useTiebreakerMutation((data) => {
    return fetchApiEndpoint(`/api/v1/pgaoaroster/entries/${entry.entry_id}/tiebreakers.json`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  });
  const { fireEvent } = useAnalytics();

  useEffect(() => {
    if (modalContent) {
      fireEvent({ event: "Open", params: "Fantasy: Tie Breaker" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalContent]);

  const initialValues = {
    tiebreaker: entry?.tiebreaker,
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TiebreakerInputs>();
  const onSubmit: SubmitHandler<TiebreakerInputs> = async (data) => {
    mutation.mutate(
      { tiebreaker: parseInt(data.tiebreaker) },
      {
        onSuccess: (data) => {
          dispatch(setCurrentRoster(data));
          dismissModal();
          showModal(<RosterCompleteModal handleClearCategory={handleClearCategory} />);
        },
        onError: () => {
          setGenericError("Something went wrong. Please try again. If it happens again please refresh your browser");
        },
      },
    );
  };

  return (
    <StyledTiebreaker>
      {!gameState ? (
        <LoadingWrapper>Loading ...</LoadingWrapper>
      ) : (
        <>
          {correctAnswer == 0 ? (
            <>
              {allPlayersTeedOff && <LockedCategory />}

              <h1>Tiebreaker</h1>
              <p>How many total birdies will be made throughout the duration of the tournament?</p>
              <form
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                onSubmit={handleSubmit(onSubmit)}
              >
                <InputContainer
                  style={{ width: "100%", alignItems: "center", justifyContent: "center", margin: "25px" }}
                >
                  {genericError && (
                    <ErrorContainer>{<GenericFormError>{genericError}</GenericFormError>}</ErrorContainer>
                  )}

                  {errors.tiebreaker?.type && (
                    <ErrorContainer>
                      {errors.tiebreaker?.type === "required" && <span className="error">Tiebreaker is required.</span>}
                    </ErrorContainer>
                  )}
                  <input
                    type="number"
                    placeholder=""
                    pattern="[0-9]*"
                    defaultValue={initialValues.tiebreaker}
                    style={{ width: "80px" }}
                    {...register("tiebreaker", { required: true })}
                    disabled={allPlayersTeedOff}
                  />
                </InputContainer>
                <ButtonGroup>
                  <Button onClick={handleSubmit(onSubmit)} disabled={allPlayersTeedOff}>
                    Submit Tiebreaker
                  </Button>
                </ButtonGroup>
              </form>
            </>
          ) : (
            <TiebreakerResultsContainer>
              <h1>Tiebreaker</h1>
              <TiebreakerQuestionContainer>
                <TiebreakerQuestionWrapper>
                  <TiebreakerQAIcon>Q:</TiebreakerQAIcon>
                  <TiebreakerQuestion>
                    How many total birdies will be made throughout the duration of the Tournament?
                  </TiebreakerQuestion>
                </TiebreakerQuestionWrapper>
              </TiebreakerQuestionContainer>
              <TiebreakerAnswerContainer>
                <TiebreakerAnswerWrapper>
                  <TiebreakerQAIcon>A:</TiebreakerQAIcon>
                  <TiebreakerResultsWrapper>
                    <AnswerWrapper>
                      {entry?.tiebreaker === correctAnswer ? (
                        <IoMdCheckmark style={{ color: "rgb(27, 171, 83)", fontSize: "30px" }} />
                      ) : (
                        <MdOutlineClose style={{ color: "rgb(200, 20, 20)", fontSize: "30px" }} />
                      )}
                      <Count>
                        {entry?.tiebreaker.toLocaleString()} <span>(Your Answer)</span>
                      </Count>
                    </AnswerWrapper>
                    <AnswerWrapper>
                      <IoMdCheckmark style={{ color: "rgb(27, 171, 83)", fontSize: "30px" }} />
                      <Count>
                        {correctAnswer.toLocaleString()} <span>(Correct Answer)</span>
                      </Count>
                    </AnswerWrapper>
                  </TiebreakerResultsWrapper>
                </TiebreakerAnswerWrapper>
              </TiebreakerAnswerContainer>
            </TiebreakerResultsContainer>
          )}
        </>
      )}
    </StyledTiebreaker>
  );
};

export default Tiebreaker;

const LoadingWrapper = styled.div`
  display: flex;
  padding: 20px;
  font-size: 28px;
  font-weight: 400;
`;

const TiebreakerResultsContainer = styled.div`
  display: flex;
  padding: 20px;
  width: 100%;
  align-items: center;

  h1 {
    @media (max-width: ${breakpoints.mobile}) {
      font-size: 20px;
      font-weight: 600;
      padding: 0 20px;
      margin-bottom: 10px;
      align-items: inherit;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
    text-align: left;
    align-items: self-start;

    h1 {
      padding: 0;
    }
  }
`;

const TiebreakerQuestionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0;

  @media (max-width: ${breakpoints.mobile}) {
    margin-bottom: 20px;
  }
`;

const TiebreakerQuestionWrapper = styled.div`
  display: flex;
  margin: 0 40px;
  align-items: center;

  @media (max-width: ${breakpoints.mobile}) {
    margin: 0;
    align-items: flex-start;
  }
`;

const TiebreakerQuestion = styled.div`
  display: flex;
`;

const TiebreakerQAIcon = styled.div`
  color: rgb(175, 145, 87);
  font-family: SourceSerifPro-SemiBold;
  font-size: 40px;
  font-weight: 600;
  height: 50px;
  letter-spacing: 0px;
  margin-right: 20px;
`;

const TiebreakerAnswerContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const TiebreakerAnswerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${breakpoints.mobile}) {
    align-items: flex-start;
  }
`;

const TiebreakerResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;

  @media (max-width: ${breakpoints.mobile}) {
    width: 100%;
  }
`;

const AnswerWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${breakpoints.mobile}) {
    margin-bottom: 20px;
  }
`;

const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-weight: bold;
  margin-left: 10px;

  span {
    font-size: 16px;
    margin-left: 10px;
  }
`;
