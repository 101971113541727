import styled from "styled-components";
import { breakpoints } from "src/styles/styleUtils";
import { IoLockClosedOutline } from "react-icons/io5";

const LockedCategoryContainer = styled.div`
  position: absolute;
  background: #c62020;
  color: #fff;
  top: -27px;
  left: -13px;
  display: inline-block;
  font-weight: 900;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  border-radius: 100px;
  transform: translateY(10px);
  z-index: 9;
  font-size: 20px;

  p {
    margin: 0 !important;
    font-size: 12px;
    transform: translateY(1px);
  }

  @media (max-width: ${breakpoints.mobile}) {
    top: -25px;
    left: -10px;
    height: 30px;
    width: 30px;
  }
`;

export default function LockedCategory() {
  return (
    <LockedCategoryContainer>
      <IoLockClosedOutline />
    </LockedCategoryContainer>
  );
}
