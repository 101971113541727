import { Player, Game, GAMESTATE, CATEGORY_STATE, GameRound } from "../constants/interfaces/game";
import { Pick } from "src/hooks/useClientContext";
import { RosterEntry } from "src/hooks/useClientContext";

export const getPlayerFromID = (playerId: number, game: Game): Player | null => {
  if (game) {
    if (game.players) {
      return game.players.find((player) => player.player_id === playerId);
    }
  }
  return null;
};

export const getPlayersFromEntry = (entry: RosterEntry, game: Game): Array<Player | null> => {
  const currentRoundPicks = entry?.picks?.filter((pick) => pick.round_id === game?.current_round_id);
  const tempPicks: Array<Player | null> = [null, null, null, null, null, null];
  if (entry && entry.picks) {
    for (let i = 0; i < 6; i++) {
      if (currentRoundPicks[i]) {
        const slotIndex = currentRoundPicks[i][`slot_id`] - 1;
        tempPicks[slotIndex] = getPlayerFromID(currentRoundPicks[i].player_id, game);
      }
    }
  }
  return tempPicks;
};

function translateRound(roundId: number) {
  return roundId - 8;
}

export const getGameState = (game: Game): string => {
  if (game) {
    if (game.settings) {
      if (game.settings.roster_creation_state === GAMESTATE.ROSTER_CREATION_OPEN) return GAMESTATE.ROSTER_CREATION_OPEN;
      else if (game.settings.roster_creation_state === GAMESTATE.ROSTER_CREATION_LOCKED)
        return GAMESTATE.ROSTER_CREATION_LOCKED;
    }
  }
};

export const getGroupLeaderboardState = (game: Game): boolean => {
  return game?.settings?.show_leaderboard;
};

export const getLockedCategoryState = (game: Game, category: string) => {
  if (game) {
    switch (category) {
      case "USAPlayer":
        return game.settings.us_category_state === CATEGORY_STATE.USA_CATEGORY_LOCKED
          ? "locked"
          : CATEGORY_STATE.USA_CATEGORY_OPEN;
      case "Corebridge":
        return game.settings.corebridge_category_state === CATEGORY_STATE.COREBRIDGE_CATEGORY_LOCKED
          ? "locked"
          : CATEGORY_STATE.COREBRIDGE_CATEGORY_OPEN;
      case "InternationalPlayer":
        return game.settings.international_category_state === CATEGORY_STATE.INTERNATIONAL_CATEGORY_LOCKED
          ? "locked"
          : CATEGORY_STATE.INTERNATIONAL_CATEGORY_OPEN;
      case "pgaChampion":
        return game.settings.past_champion_category_state === CATEGORY_STATE.PAST_CHAMP_CATEGORY_LOCKED
          ? "locked"
          : CATEGORY_STATE.COREBRIDGE_CATEGORY_OPEN;
      default:
        break;
    }
  }
};

export const getCurrentRound = (game: Game): GameRound => {
  if (game) {
    if (game.rounds) {
      return game.rounds.find((round) => round.id === game.current_round_id);
    }
  }
};

export const getRoundByNumber = (roundNumber: number, game: Game): GameRound => {
  if (game) {
    if (game.rounds) {
      return game.rounds.find((round) => translateRound(round.id) === roundNumber);
    }
  }
};

export const getHasPicks = (picks: Array<Player | null>): boolean => {
  if (!picks.includes(null)) {
    return true;
  }
  return false;
};

export const emptyHeadshot = () => {
  return `https://pga-tour-res.cloudinary.com/image/upload/c_fill,d_headshots_default.png,dpr_2.0,f_auto,g_face:center,h_350,q_auto,w_280/headshots_0.png`;
};

export function calcPoints(prs: number) {
  if (prs <= -3) return 8;
  else if (prs <= -2) return 5;
  else if (prs === -1) return 2;
  else if (prs === 0) return 0;
  else if (prs === 1) return -1;
  else if (prs >= 2) return -3;
}

export function getPicks(picks: Pick[], game) {
  let picksBySlots = {};
  const sortedPicks = picks?.sort((pickA, pickB) => pickA.round_id - pickB.round_id);

  if (sortedPicks) {
    Object.keys(sortedPicks).forEach((pick) => {
      picksBySlots = {
        ...picksBySlots,
        [picks[pick].slot_id]: { playerData: getPlayerFromID(sortedPicks[pick].player_id, game) },
      };
    });

    let picksMap = { 1: null, 2: null, 3: null, 4: null, 5: null };
    Object.keys(picksMap).forEach((pick) => {
      picksMap = {
        ...picksMap,
        [pick]: picksBySlots[pick] ? picksBySlots[pick]?.playerData : null,
      };
    });
    return picksMap;
  }
}
