import React, { FunctionComponent, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components";
import { Button, ButtonGroup } from "../../styles/buttons.styled";
import InputContainer from "../inputs/InputContainer";
import { useModal } from "../Modal";
import { ModalBody, ModalHeader } from "../Modal/StandardModal";
import { useSession } from "../../hooks/useSession";
import { GenericFormError, ErrorContainer } from "./SignIn.styled";
import useClientContext, { setCurrentRoster } from "src/hooks/useClientContext";
import { pxToRem } from "../../styles/styleUtils";

const StyledSignUpModal = styled.div`
  overflow: hidden;

  a {
    text-decoration: underline;
    color: ${(props) => props.theme.primary};
  }
`;

const CreatEntryDescription = styled.p`
  text-align: center;
  font-size: ${pxToRem(16)};
  margin: 0 30px;
  max-width: 500px;
`;

type EntryInputs = {
  name: string;
};

const EntryModal: FunctionComponent = () => {
  const [genericError, setGenericError] = useState<string>("");
  const { dismissModal } = useModal();
  const { entrySubmit, token } = useSession();
  const [, dispatch] = useClientContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EntryInputs>();

  const onSubmit: SubmitHandler<EntryInputs> = (data) => {
    entrySubmit(data, token)
      .then((res) => {
        if (res) {
          dispatch(setCurrentRoster(res));
          dismissModal();
        }
      })
      .catch((e) => {
        setGenericError(e);
      });
  };

  return (
    <StyledSignUpModal>
      <ModalHeader hideClose={true} title="Create an Entry Name">
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <CreatEntryDescription>
            Create your entry name to stand out in your group and from participants around the world! Entries will
            automatically be added to our PGA Championship Overall Group.
          </CreatEntryDescription>
        </div>
      </ModalHeader>
      <ModalBody style={{ paddingTop: 0 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ErrorContainer>
            {genericError && <GenericFormError style={{ textAlign: "center" }}>{genericError}</GenericFormError>}
          </ErrorContainer>
          <InputContainer
            style={{
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: 32,
              marginTop: 5,
            }}
          >
            <ErrorContainer>
              {errors.name?.type === "required" && <span className="error">Entry name is required.</span>}
            </ErrorContainer>
            <input
              style={{ width: 215 }}
              type="text"
              placeholder="Entry Name"
              {...register("name", { required: true })}
            />
          </InputContainer>
          <ButtonGroup style={{ marginTop: 11 }}>
            <Button type="submit" onClick={handleSubmit(onSubmit)}>
              Save Entry Name
            </Button>
          </ButtonGroup>
        </form>
      </ModalBody>
    </StyledSignUpModal>
  );
};

export default EntryModal;
