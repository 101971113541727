import { Game } from "../constants/interfaces/game";
import { IServerPageProps } from "../constants/interfaces/api";
import { QUERIES } from "../constants/queries";
import { QueryClient, useQuery, UseQueryResult } from "react-query";
import settings from "../settings";
import { User } from "src/constants/interfaces/auth";
import { serverSideAuthCheck } from "src/hooks/auth";

export const fetchRoster = async () => {
  const res = await fetch(`${settings.API_URL}/api/static-v1/pgaoaroster/game.json`);
  const games = await res.json();
  return games;
};

export const useRosterQuery = (): UseQueryResult<Game> => {
  return useQuery(QUERIES.ROSTER, fetchRoster, {
    staleTime: 1000 * 60 * 10,
    cacheTime: 1000 * 60 * 10,
  });
};

export const getServerSidePageProps = async (ctx, reqRoster = false): Promise<IServerPageProps> => {
  const { query, req, res } = ctx;
  const fetchingAuth = !!query.code;
  let user: User | null = null;
  const queryClient = new QueryClient();

  if (!fetchingAuth && !queryClient.getQueryData(QUERIES.SESSION)) {
    await queryClient.prefetchQuery(QUERIES.SESSION, () => serverSideAuthCheck(req, res));
    user = queryClient.getQueryData(QUERIES.SESSION);
    if (typeof user === "undefined") {
      user = null;
    }
  } else {
    user = queryClient.getQueryData(QUERIES.SESSION);
  }
  if (!queryClient.getQueryData(QUERIES.ROSTER) && reqRoster) {
    await queryClient.prefetchQuery(QUERIES.ROSTER, fetchRoster);
  }
  const countryCode = req.headers["cloudfront-viewer-country"] || "US";

  return {
    user,
    fetchingAuth,
    countryCode,
  };
};
