import styled, { css } from "styled-components";
import { palette } from "./styleUtils";

const CoreButtonStyles = css`
  font-size: 16px;
  line-height: 1em;
  border-radius: 50px;
  font-weight: bold;
  height: 44px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 212px;

  :disabled {
    opacity: 0.3;
  }
`;

export const ButtonStyles = css`
  ${CoreButtonStyles}
  color: ${palette.buttonTextColor};
  text-decoration: none;
  transition: all 0.2s ease-in-out;
`;

export const Button = styled.button`
  ${ButtonStyles}
  background-color: ${palette.primary1};
  border: 1px solid ${palette.primary1};

  &:hover {
    background-color: ${palette.neutral2};
    border-color: ${palette.primary1};
    color: ${palette.primary1};
  }

  &:active {
    background-color: #1f4f80;
  }
`;

export const SecondaryButton = styled.button`
  ${ButtonStyles}
  border: 1px solid ${palette.primary1};
  background-color: white;
  color: ${palette.primary1};
  @media (hover: hover) {
    :hover:not(:active) {
      background-color: #f2f4f7;
    }
  }

  :active {
    background-color: #f2f4f7;
  }
`;

export const ButtonClear = styled(Button)`
  background-color: transparent;
  border: 1px solid #fff;

  @media (hover: hover) {
    :hover:not(:active) {
      background-color: #ffffff54 !important;
      border: 1px solid #fff;
    }
  }

  :active {
    background-color: #ffffff54 !important;
    border: 1px solid #fff;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
