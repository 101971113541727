import { MutationFunction, useMutation, useQueryClient } from "react-query";
import { RosterEntry, Pick, Group } from "./useClientContext";
import { QUERIES } from "src/constants/queries";
export interface EntryResponse {
  entry_id?: number;
  name: string;
  total_rank: number | null;
  total_points: number | null;
  picks: Pick[];
  group_memberships: Group[];
  has_made_first_five?: boolean;
  tiebreaker_water_balls?: number;
  tiebreaker: number | null;
}

export interface GroupsResponse {
  creator_id: number;
  entries_count?: number;
  entry_count?: number;
  group_id: number;
  group_name?: string;
  name?: string;
  group_points: number;
  group_rank: number;
  group_description?: string;
  description?: string;
  join_token: string;
  top_user_name: string | null;
  group_memberships: Group[];
}

export interface JoinGroupsResponse {
  entry_id?: number;
  name: string;
  total_rank: number | null;
  total_points: number | null;
  picks: Pick[];
  group_memberships: Group[];
}

export interface TiebreakerResponse {
  tiebreaker_water_balls: number;
  tiebreaker: number;
}

export default function useEntryMutation(fn: MutationFunction<EntryResponse>) {
  const queryClient = useQueryClient();
  return useMutation(fn, {
    onSuccess: (data) => {
      queryClient.setQueryData<RosterEntry | null>(QUERIES.ENTRY, data);
    },
    onError: (error) => {
      alert(error);
    },
  });
}

export const useGroupsMutation = (fn: MutationFunction<GroupsResponse>) => {
  return useMutation(fn);
};

export const JoinGroupsMutation = (fn: MutationFunction<JoinGroupsResponse>) => {
  return useMutation(fn);
};

export const useTiebreakerMutation = (fn: MutationFunction<TiebreakerResponse>) => {
  return useMutation(fn);
};
