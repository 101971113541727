import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/styleUtils";
import CloseButton from "./CloseButton";

export const StyledModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 45px 15px 15px 21px;
  border-bottom: none;

  > h1 {
    text-align: center;
    margin: 0;
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 0.27px;
  }
  > .content {
    font-size: 14px;
    line-height: 21px;
  }

  @media (min-width: ${breakpoints.tablet}) and (min-height: 400px) {
    background-color: unset;
    border-bottom: none;
    text-align: center;
    align-items: center;

    > h1 {
      font-size: 32px;
      line-height: 28px;
    }
    > .content {
      height: 100%;
      max-width: 530px;
      overflow-y: auto;
      font-size: 18px;
      line-height: 30px;
    }
  }
`;

export const ModalBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 25px 15px;
`;

interface ModalHeaderProps {
  title: string;
  hideClose?: boolean;
}

export const ModalHeader: FunctionComponent<ModalHeaderProps> = ({ title, children, hideClose }) => (
  <StyledModalHeader>
    {!hideClose && <CloseButton />}
    <h1>{title}</h1>
    <div className="content">{children}</div>
  </StyledModalHeader>
);
