import styled from "styled-components";
import { palette } from "../styles/styleUtils";

const StyledSubHeading = styled.h3`
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  align-self: center;
  color: ${palette.neutral4};
`;

export default function SubHeading({ title }) {
  return <StyledSubHeading>{title}</StyledSubHeading>;
}
