import React, { FunctionComponent, useState, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components";
import { Button, ButtonGroup } from "../styles/buttons.styled";
import InputContainer from "./inputs/InputContainer";
import { useModal } from "./Modal";
import { useSession } from "../hooks/useSession";
import CloseButton from "./Modal/CloseButton";
import { GenericFormError, ErrorContainer } from "./SignIn/SignIn.styled";
import { palette, breakpoints } from "../styles/styleUtils";
import useAnalytics from "../hooks/useAnalytics";

const Form = styled.form`
  width: 100%;
  padding: 70px 5px;

  h2 {
    font-size: 35px;
    text-align: center;
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 35px;
    text-align: center;
    margin-left: 30px;
    margin-right: 30px;
  }

  .input-container {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    @media (max-width: ${breakpoints.mobile}) {
      width: 90vw;
    }
  }
`;

type AccountSettingsInputs = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password: string;
};

interface AccountSettingsFormProps {
  hasErrors: string;
  handleAccountSettingsJoinGroup?: (entry) => void;
}

const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }

  .label-text {
    text-align: right;
    width: 120px;
    margin-right: 25px;
    font-weight: bold;
    color: ${palette.neutral5};

    @media (max-width: ${breakpoints.mobile}) {
      text-align: left;
    }
  }

  div {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .error {
    top: -22px;
    position: absolute;

    @media (max-width: ${breakpoints.mobile}) {
      right: 0;
    }
  }
`;

const StyledInput = styled.input`
  width: 400px;
  @media (max-width: ${breakpoints.mobile}) {
    width: 90vw;
  }
`;

export const AccountSettingsModal: FunctionComponent<AccountSettingsFormProps> = ({ hasErrors }) => {
  const [genericError, setGenericError] = useState<string>(hasErrors);
  const { dismissModal, modalContent } = useModal();
  const { updateSubmit, user } = useSession();
  const [passwordsDoNotMatch, setPasswordsDoNotMatch] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AccountSettingsInputs>();
  const { fireEvent } = useAnalytics();

  useEffect(() => {
    if (modalContent) {
      fireEvent({ event: "Open", params: "Fantasy Account Settings Modal" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalContent]);

  function checkPasswordMatch(data) {
    if (data.confirm_password === data.password) {
      setPasswordsDoNotMatch(false);
      return true;
    } else {
      setPasswordsDoNotMatch(true);
      return false;
    }
  }

  const onSubmit: SubmitHandler<AccountSettingsInputs> = (data) => {
    if (checkPasswordMatch(data)) {
      updateSubmit(data)
        .then(() => {
          dismissModal();
        })
        .catch((e) => {
          setGenericError(e);
        });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <CloseButton />
      <h2>Account Settings</h2>
      <p>Forgot your password or need to reset it? Manage your account settings below.</p>
      <ErrorContainer>
        {genericError && (
          <GenericFormError style={{ textAlign: "center", transform: "translateY(-10px)" }}>
            {genericError}
          </GenericFormError>
        )}
      </ErrorContainer>
      <InputContainer className="input-container">
        <StyledInputContainer>
          <span className="label-text">First Name</span>
          <div>
            {errors.first_name?.type === "required" && <span className="error">First name is required.</span>}
            <StyledInput
              defaultValue={user.first_name}
              type="text"
              placeholder={`First Name`}
              {...register("first_name", { required: true })}
            />
          </div>
        </StyledInputContainer>
      </InputContainer>
      <InputContainer className="input-container">
        <StyledInputContainer>
          <span className="label-text">Last Name</span>
          <div>
            {errors.last_name?.type === "required" && <span className="error">Last name is required.</span>}
            <StyledInput
              defaultValue={user.last_name}
              type="text"
              placeholder={`Last Name`}
              {...register("last_name", { required: true })}
            />
          </div>
        </StyledInputContainer>
      </InputContainer>
      <InputContainer className="input-container">
        <StyledInputContainer>
          <span className="label-text">Email Address</span>
          <div>
            {errors.email?.type === "required" && <span className="error">Email is required.</span>}
            {errors.email?.type === "minLength" && (
              <span className="error">Enter a longer email address, at least 6 characters.</span>
            )}
            <StyledInput
              defaultValue={user.email}
              type="text"
              placeholder={`Email`}
              {...register("email", { required: true })}
            />
          </div>
        </StyledInputContainer>
      </InputContainer>
      <InputContainer className="input-container">
        <StyledInputContainer>
          <span className="label-text">Password</span>
          <div>
            {errors.password?.type === "invalid" && <span className="error">Email or password is not correct.</span>}
            <StyledInput type="password" placeholder="Update Password" {...register("password")} />
          </div>
        </StyledInputContainer>
      </InputContainer>
      <InputContainer className="input-container">
        <StyledInputContainer>
          <span className="label-text"></span>
          <div>
            {errors.password?.type === "invalid" && <span className="error">Email or password is not correct.</span>}
            {passwordsDoNotMatch && <span className="error">Passwords do not match</span>}
            <StyledInput type="password" placeholder="Confirm Password" {...register("confirm_password")} />
          </div>
        </StyledInputContainer>
      </InputContainer>
      <ButtonGroup style={{ marginTop: 60 }}>
        <Button onClick={handleSubmit(onSubmit)}>Save Settings</Button>
      </ButtonGroup>
    </Form>
  );
};
