export interface RosterSlot {
  slot_id: number;
  pickId: number | null;
}

export interface Player {
  player_id: number;
  first_name: string;
  last_name: string;
  team: string;
  headshot_url: string;
  remote_id: string;
  round_stats?: GameRound[];
  points: string;
  live: boolean;
  odds: Odds;
  position: number;
  categories: string[];
}

export interface GameRound {
  id?: number;
  started: boolean;
  round_number: number;
  status: string;
  scorecard?: Scorecard;
  start_time?: string;
  cup_points?: number;
}

export type current = number;
export interface Odds {
  WINNER: OddsObject;
  QUALIFICATION: current;
  TOP_RANK: OddsObject;
  OTHER: OddsObject;
}

export interface OddsObject {
  change: string;
  current: number;
  name: string;
  previous: number;
}
export interface GameSettings {
  game_state: string;
  counts_down_to: string;
  odds_timestamp: string;
  roster_creation_state: string; // roster_creation_open, roster_creation_locked
  corebridge_category_state: string;
  international_category_state: string;
  us_category_state: string;
  past_champion_category_state: string;
  show_leaderboard: boolean;
  tiebreaker: number;
}

export interface Round {
  id: number;
  status: string;
  round_number: number;
  cup_points: number;
  scorecard: Scorecard | null;
  started: boolean;
}

export interface Game {
  uuid: "pgaoa";
  course_pars: [
    {
      hole_10_par: number;
      hole_11_par: number;
      hole_12_par: number;
      hole_13_par: number;
      hole_14_par: number;
      hole_15_par: number;
      hole_16_par: number;
      hole_17_par: number;
      hole_18_par: number;
      hole_1_par: number;
      hole_2_par: number;
      hole_3_par: number;
      hole_4_par: number;
      hole_5_par: number;
      hole_6_par: number;
      hole_7_par: number;
      hole_8_par: number;
      hole_9_par: number;
    },
  ];
  total_entry_count: number;
  rounds: GameRound[];
  players: Player[];
  settings: GameSettings;
  current_round_id: number;
}

export interface TieBreakerData {
  tiebreaker_water_balls: number;
}

export interface Scorecard {
  hole_10_prs: number;
  hole_10_score: number;
  hole_11_prs: number;
  hole_11_score: number;
  hole_12_prs: number;
  hole_12_score: number;
  hole_13_prs: number;
  hole_13_score: number;
  hole_14_prs: number;
  hole_14_score: number;
  hole_15_prs: number;
  hole_15_score: number;
  hole_16_prs: number;
  hole_16_score: number;
  hole_17_prs: number;
  hole_17_score: number;
  hole_18_prs: number;
  hole_18_score: number;
  hole_1_prs: number;
  hole_1_score: number;
  hole_2_prs: number;
  hole_2_score: number;
  hole_3_prs: number;
  hole_3_score: number;
  hole_4_prs: number;
  hole_4_score: number;
  hole_5_prs: number;
  hole_5_score: number;
  hole_6_prs: number;
  hole_6_score: number;
  hole_7_prs: number;
  hole_7_score: number;
  hole_8_prs: number;
  hole_8_score: number;
  hole_9_prs: number;
  hole_9_score: number;
  par_relative_score: number;
  total: number;
}

export const GAMESTATE = {
  PRE_PICK: "pre_picks",
  PICKS_OPEN: "picks_open",
  PICKS_LOCKED: "picks_locked",
  ROSTER_CREATION_OPEN: "roster_creation_open",
  ROSTER_CREATION_LOCKED: "roster_creation_locked",
};

export const CATEGORY_STATE = {
  INTERNATIONAL_CATEGORY_LOCKED: "international_category_locked",
  INTERNATIONAL_CATEGORY_OPEN: "international_category_open",
  USA_CATEGORY_LOCKED: "us_category_locked",
  USA_CATEGORY_OPEN: "us_category_open",
  COREBRIDGE_CATEGORY_LOCKED: "corebridge_category_locked",
  COREBRIDGE_CATEGORY_OPEN: "corebridge_category_open",
  PAST_CHAMP_CATEGORY_LOCKED: "past_champion_category_locked",
  PAST_CHAMP_CATEGORY_OPEN: "past_champion_category_open",
};
