import React, { Dispatch, FunctionComponent, SetStateAction, useState, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components";
import { Button, ButtonGroup } from "../../styles/buttons.styled";
import { breakpoints, palette } from "../../styles/styleUtils";
import InputContainer from "../inputs/InputContainer";
import { useModal } from "../Modal";
import { StyledModalHeader } from "../Modal/StandardModal";
import SignUpModal from "./SignUpmodal";
import CloseButton from "../Modal/CloseButton";
import { ButtonClear } from "../../styles/buttons.styled";
import EntryModal from "./EntryModal";
import { useSession } from "../../hooks/useSession";
import { ErrorContainer, GenericFormError, GenericFormMessage } from "./SignIn.styled";
import { useRosterQuery } from "src/hooks/api";
import { forgotUserPassword, resetUserPassword } from "src/hooks/auth";
import { getGameState } from "src/utils/game";
import { GAMESTATE } from "src/constants/interfaces/game";
import settings from "../../settings";
import useAnalytics from "../../hooks/useAnalytics";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${breakpoints.tablet}) {
    background-color: ${palette.neutral1};
    padding-bottom: 20px;
  }
`;

type SignInInputs = {
  email: string;
  password: string;
};

type ForgotPasswordInputs = {
  email: string;
};

type ResetPasswordInputs = {
  password: string;
};

interface ForgotPasswordFormProps {
  initialEmail: string;
  setShowForgotPassword: Dispatch<SetStateAction<string>>;
}

const ForgotPasswordForm: FunctionComponent<ForgotPasswordFormProps> = ({ initialEmail, setShowForgotPassword }) => {
  const [genericError, setGenericError] = useState<string>("");
  const [forgotMessage, setForgotMessage] = useState<string>("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordInputs>({ defaultValues: { email: initialEmail } });

  const onSubmit: SubmitHandler<ForgotPasswordInputs> = (data) => {
    forgotUserPassword(data.email)
      .then((res) => {
        if (res) {
          if (res === true) setForgotMessage(`If that email is registered, you'll receive additional steps`);
        }
      })
      .catch((e) => {
        setGenericError(e);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ErrorContainer>
        {genericError && <GenericFormError>{genericError}</GenericFormError>}
        {forgotMessage && <GenericFormMessage>{forgotMessage}</GenericFormMessage>}
      </ErrorContainer>
      <InputContainer
        style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
      >
        <input
          style={{ width: 212 }}
          type="text"
          placeholder="Email Address"
          {...register("email", { required: true, minLength: 6 })}
        />
        {errors.email?.type === "required" && <span className="error">Email is required.</span>}
        {errors.email?.type === "minLength" && (
          <span className="error">Enter a longer email address, at least 6 characters.</span>
        )}
      </InputContainer>
      <ButtonGroup style={{ marginTop: 11 }}>
        <Button onClick={handleSubmit(onSubmit)}>Reset Password</Button>
      </ButtonGroup>
      <a
        href="#"
        style={{ marginTop: 26 }}
        onClick={(e) => {
          e.preventDefault();
          setShowForgotPassword(null);
        }}
      >
        Return to Sign In
      </a>
    </form>
  );
};

interface ResetPasswordFormProps {
  token: string;
  setShowResetPassword: Dispatch<SetStateAction<boolean>>;
}

const ResetPasswordForm: FunctionComponent<ResetPasswordFormProps> = ({ token, setShowResetPassword }) => {
  const [genericError, setGenericError] = useState<string>("");
  const [passwordResetSuccess, setPasswordResetSuccess] = useState("");
  const [genericMessage] = useState<string>("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordInputs>();

  const onSubmit: SubmitHandler<ResetPasswordInputs> = (data) => {
    resetUserPassword({ token: token, password: data.password })
      .then((res) => {
        setPasswordResetSuccess("Password Reset Successfully. Redirecting...");
        setTimeout(() => {
          if (res) {
            if (settings.APP_ENV !== "production") {
              window.location.href = "https://pgachampionship.uat.pga.golf.psdops.com/fantasy";
            } else {
              window.location.href = "https://www.pgachampionship.com/fantasy";
            }
          }
        }, 5000);
      })
      .catch((e) => {
        setGenericError(e);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ErrorContainer>
        {genericError && <GenericFormError>{genericError}</GenericFormError>}
        {genericMessage && <GenericFormMessage>{GenericFormMessage}</GenericFormMessage>}
      </ErrorContainer>
      <InputContainer style={{ display: "flex", flexDirection: "column" }}>
        {errors.password?.type === "required" && <span className="error">Password is required.</span>}
        {errors.password?.type === "minLength" && <span className="error">Enter a longer password.</span>}
        {passwordResetSuccess !== "" && <span style={{ marginBottom: 10 }}>{passwordResetSuccess}</span>}
        <input type="password" placeholder="New Password" {...register("password", { required: true, minLength: 4 })} />
      </InputContainer>
      <ButtonGroup style={{ marginTop: 11 }}>
        <Button onClick={handleSubmit(onSubmit)}>Reset Password</Button>
      </ButtonGroup>
      <a
        href="#"
        style={{ marginTop: 26 }}
        onClick={(e) => {
          e.preventDefault();
          setShowResetPassword(false);
        }}
      >
        Return to Sign In
      </a>
    </form>
  );
};

const StyledSignInModal = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow: auto;

  > div {
    &.signin {
      a {
        font-size: 12px;
        margin-bottom: 32px;
      }
    }
  }

  @media (min-width: ${breakpoints.tablet}) and (min-height: 400px) {
    height: 600px;
    flex-direction: row;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      &.signin {
        padding-top: 100px;
        flex: 0.6;
        form {
          a {
            display: block;
            text-align: center;
          }
        }
      }
      &.signup {
        padding-top: 100px;
        color: #fff;
        flex: 0.4;
        position: relative;
        background: ${palette.primary2} !important;
        height: 100%;

        h1 {
          padding: 0 60px;
        }
        h1,
        p {
          color: #fff;
          text-align: center;
        }
        p {
          font-size: 18px;
          padding: 0 40px;
          margin-bottom: 20px;
        }
      }
    }
  }
`;

const SignInModalSectionHeader = styled(StyledModalHeader)`
  @media screen and (max-width: ${breakpoints.tablet}) {
    background-color: white;
  }

  &.sign-in-header {
    @media screen and (max-width: ${breakpoints.mobile}) {
      padding-bottom: 30px;
    }
  }

  h1 {
    margin-top: 30px;
    line-height: 40px;
    font-size: 32px;
    text-transform: none;
    margin-bottom: 0 !important;

    @media screen and (max-width: ${breakpoints.mobile}) {
      font-size: 24px;
      line-height: 26px;
    }
  }
  span {
    display: block;
  }
`;

const SignInInputContainer = styled(InputContainer)`
  width: 250px;
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled(ButtonClear)`
  margin: 30px auto 60px auto;
`;

const CreateAccountDescription = styled.p`
  align-self: center;
  @media screen and (max-width: ${breakpoints.tablet}) {
    max-width: 500px;
    margin: 10px;
    align-self: center;
  }
`;

interface SignInFormProps {
  setShowForgotPassword: Dispatch<SetStateAction<string>>;
  origin: string;
  hasErrors: string;
  handleSignInJoinGroup?: (entry) => void;
}

const SignInForm: FunctionComponent<SignInFormProps> = ({ setShowForgotPassword, hasErrors }) => {
  const { data: game } = useRosterQuery();
  const [genericError, setGenericError] = useState<string>(hasErrors);
  const { dismissModal, showModal, modalContent } = useModal();
  const { loginSubmit } = useSession();
  const gameState: string = getGameState(game);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<SignInInputs>();
  const { fireEvent } = useAnalytics();

  useEffect(() => {
    if (modalContent) {
      fireEvent({ event: "Open", params: "Fantasy Log In Modal" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalContent]);

  const onSubmit: SubmitHandler<SignInInputs> = (data) => {
    loginSubmit(data)
      .then((res) => {
        fireEvent({ event: "Sign_in", params: "Sign in: Success" });
        if (res.pgaoaroster_entries.length === 0 && gameState === GAMESTATE.ROSTER_CREATION_OPEN)
          showModal(<EntryModal />);
        else dismissModal();
      })
      .catch((e) => {
        fireEvent({ event: "Sign_in", params: "Sign in: Error" });
        setGenericError(e);
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ErrorContainer>{genericError && <GenericFormError>{genericError}</GenericFormError>}</ErrorContainer>
      <SignInInputContainer style={{ flexDirection: "column" }}>
        <ErrorContainer>
          {errors.email?.type === "required" && <span className="error">Email is required.</span>}
          {errors.email?.type === "minLength" && (
            <span className="error">Enter a longer email address, at least 6 characters.</span>
          )}
        </ErrorContainer>
        <input type="text" placeholder="Email Address" {...register("email", { required: true, minLength: 6 })} />
      </SignInInputContainer>
      <SignInInputContainer style={{ flexDirection: "column" }}>
        <ErrorContainer>
          {errors.password?.type === "required" && <span className="error">Password is required.</span>}
          {errors.password?.type === "invalid" && <span className="error">Email or password is not correct.</span>}
        </ErrorContainer>
        <input type="password" placeholder="Password" {...register("password", { required: true })} />
      </SignInInputContainer>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          const initialEmail = getValues("email");
          setShowForgotPassword(initialEmail);
        }}
      >
        Forgot Password?
      </a>
      <ButtonGroup style={{ marginTop: 11 }}>
        <Button onClick={handleSubmit(onSubmit)}>Sign In</Button>
      </ButtonGroup>
    </Form>
  );
};

interface SignInModalProps {
  origin: string;
  isResetPassword?: boolean;
  token?: string;
  hasErrors?: string;
  handleSignInJoinGroup?: (entry) => void;
}

const SignInModal: FunctionComponent<SignInModalProps> = ({
  origin,
  isResetPassword = false,
  token = null,
  hasErrors = null,
  handleSignInJoinGroup,
}) => {
  const { showModal } = useModal();
  const [showForgotPassword, setShowForgotPassword] = useState<string>(null);
  const [showResetPassword, setShowResetPassword] = useState<boolean>(isResetPassword);
  const { isWomens } = useAnalytics();

  return (
    <StyledSignInModal>
      <div className="signin">
        <SignInModalSectionHeader className="sign-in-header">
          <CloseButton />
          <h1>
            <span>Sign In to</span>
            <span>{isWomens ? "KPMG Women's" : ""} PGA Championship Fantasy</span>
          </h1>
        </SignInModalSectionHeader>
        {showForgotPassword !== null ? (
          <ForgotPasswordForm initialEmail={showForgotPassword} setShowForgotPassword={setShowForgotPassword} />
        ) : showResetPassword ? (
          <ResetPasswordForm token={token} setShowResetPassword={setShowResetPassword} />
        ) : (
          <SignInForm
            hasErrors={hasErrors}
            setShowForgotPassword={setShowForgotPassword}
            origin={origin}
            handleSignInJoinGroup={handleSignInJoinGroup}
          />
        )}
      </div>
      <div
        style={{
          background: `${palette.primary2}`,
          color: "white",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        className="signup"
      >
        <SignInModalSectionHeader style={{ background: `${palette.primary2}`, color: "white" }}>
          <h1>
            <span>Want to join</span>
            <span>in on the fun?</span>
          </h1>
        </SignInModalSectionHeader>
        <CreateAccountDescription>
          Create an account and play {isWomens ? "KPMG Women's" : ""} PGA Championship Fantasy! Join the action and
          create your own custom groups to challenge friends and family and compete to win the Grand Prize.
        </CreateAccountDescription>
        <StyledButton onClick={() => showModal(<SignUpModal />)}>Sign Up</StyledButton>
      </div>
    </StyledSignInModal>
  );
};

export default SignInModal;
