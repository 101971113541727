import styled from "styled-components";
import { palette, breakpoints, pxToRem } from "src/styles/styleUtils";

export const GenericFormError = styled.div`
  color: #f00;
  max-width: 780px;
  margin: 10px auto;
  padding: 0 30px;
  font-size: 13px;
`;

export const GenericFormMessage = styled.div`
  color: #222;
  max-width: 780px;
  margin: 10px auto;
  padding: 0 30px;
`;

export const ErrorContainer = styled.div`
  height: 20px;
`;

export const PromotionalInfoContainer = styled.div`
  border-radius: 4px;
  padding: 15px;
  border: 1px solid ${palette.neutral3};
  margin-bottom: 10px;
  width: 800px;

  @media only screen and (max-width: ${breakpoints.tablet}) {
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    flex-flow: column !important;
    flex-direction: column !important;
    margin-bottom: 10px;

    label {
      align-self: center;
      width: 80% !important;
      margin-bottom: 25px;
    }

    input {
      width: 100%;
      margin-right: 0 !important;
    }
  }
`;

export const PromotionalInfoDescription = styled.div`
  max-width: 90%;
  text-align: center;
  margin: 0 auto;
  font-size: ${pxToRem(14)};
  margin-bottom: 5px;

  @media only screen and (max-width: ${breakpoints.mobile}) {
    max-width: 100%;
    margin: 10px;
    margin-bottom: 30px;
  }
`;

export const SupportingQuestionsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  label {
    padding: 10px;
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    position: relative;
    display: flex;
    flex-flow: row;
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    flex-flow: column !important;
    flex-direction: column !important;
    label {
      padding: 0;
    }
  }
`;

export const AddressInfoContainer = styled.div`
  @media only screen and (min-width: ${breakpoints.laptop}) {
    padding-top: 15px;
    position: relative;
    display: flex;
    flex-flow: row;
  }
`;

export const StreetContainer = styled.div`
  @media only screen and (min-width: ${breakpoints.laptop}) {
    padding-top: 15px;
    position: relative;
    display: flex;
    flex-flow: row;
  }
`;

export const RemainingAddressContainer = styled.div`
  display: flex;
  justify-content: center;

  input:first-child {
    margin-right: 20px;
  }

  label {
    width: 35%;
  }

  @media only screen and (min-width: ${breakpoints.laptop}) {
    padding-top: 15px;
    position: relative;
    display: flex;
    flex-flow: row;
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    flex-flow: column !important;
    flex-direction: column !important;
  }
`;
