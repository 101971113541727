import { useSession } from "../hooks/useSession";
import Settings from "./Icons/Settings";
import { breakpoints, FONTS, palette } from "../styles/styleUtils";
import { getGameState } from "../utils/game";
import { GAMESTATE } from "../constants/interfaces/game";
import * as Popover from "@radix-ui/react-popover";
import styled from "styled-components";
import { useModal } from "./Modal";
import { useRosterQuery } from "../hooks/api";
import { AccountSettingsModal } from "./AccountSettingsModal";
import { useEffect, useState } from "react";

export const DropdownContent = styled(Popover.Content)`
  background: ${palette.neutral1};
  box-shadow: 0px 4px 36px 0px rgba(0, 10, 22, 0.15);
  padding: 25px;
  border-radius: 4px;
  min-width: 200px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-fill-mode: forwards;
  will-change: transform, opacity;
  z-index: 10;

  p:first-child {
    margin-bottom: 15px;
  }

  @keyframes slideUp {
    0% {
      opacity: 0;
      transform: translateY(0);
    }
    100% {
      opacity: 1;
    }
  }

  &[data-state="open"] {
    &[data-side="bottom"] {
      animation-name: slideUp;
    }
  }
`;

export const DropdownItem = styled.p`
  color: #af9157;
  font-family: ${FONTS.SERIF};
  cursor: pointer;
`;

const PerformanceHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 40px;
  max-width: 1200px;
  min-width: 320px;
  display: flex;

  @media (max-width: ${breakpoints.large}) {
    padding: 0 50px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    padding: 0 30px;
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 0 7px;
  }

  .total-points-container {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: ${breakpoints.mobile}) {
      width: 100%;
    }
  }

  @media (max-width: ${breakpoints.laptop}) {
    flex-direction: column;
  }
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;

  h1 {
    margin-right: 20px;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: 28px;
    }
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 90px;
  margin: 0 30px;
  background-color: ${palette.neutral3};

  @media (max-width: ${breakpoints.mobile}) {
    margin: 0 20px;
  }
`;

const RoundsContainer = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.laptop}) {
    width: 100%;
    margin-top: 50px;
    justify-content: space-around;
  }
`;

const PointsContainer = styled.div<{ totalPoints?: boolean }>`
  display: inline-flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => props.totalPoints && "60px"};

  @media (max-width: ${breakpoints.laptop}) {
    margin-right: 0;
  }

  .points {
    margin: 0;
    transform: translateY(-10px);
    font-size: 42px;
    font-family: ${FONTS.SERIF};

    @media (max-width: ${breakpoints.mobile}) {
      font-size: 32px;
    }
  }

  .description {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    transform: translateY(-3px);
    white-space: nowrap;
  }
`;

const SettingsContainer = styled(Popover.Trigger)`
  transition: 0.2s;
  &:hover {
    transform: translateY(-2px);
  }
`;

export default function PerformanceHeader({ name, pointsByRound }) {
  const [totalPoints, setTotalPoints] = useState(0);
  const { data: game } = useRosterQuery();
  const gameState: string = getGameState(game);

  useEffect(() => {
    let totPoints = 0;
    pointsByRound.forEach((round) => {
      totPoints += round.points;
    });
    setTotalPoints(totPoints);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PerformanceHeaderContainer>
      <div className="total-points-container">
        <AccountDetailsAndSettings gameState={gameState} name={name} />
        {gameState === GAMESTATE.ROSTER_CREATION_LOCKED ? (
          <PointsContainer totalPoints={true}>
            <p className="points">{totalPoints}</p>
            <small className="description">tot points</small>
          </PointsContainer>
        ) : null}
      </div>
      {gameState === GAMESTATE.ROSTER_CREATION_LOCKED ? (
        <RoundsContainer>
          {pointsByRound.map((round, index) => (
            <>
              <div style={{ display: "flex" }} key={`Round-${round.roundNumber}`}>
                <PointsContainer>
                  <p className="points">{round.points}</p>
                  <small className="description">Round {round.roundNumber}</small>
                </PointsContainer>
              </div>
              {index !== pointsByRound.length - 1 && <Divider />}
            </>
          ))}
        </RoundsContainer>
      ) : null}
    </PerformanceHeaderContainer>
  );
}

export function AccountDetailsAndSettings({ name, gameState }) {
  return (
    <NameContainer>
      <h1>{name}</h1> <Dropdown />
      {gameState === GAMESTATE.ROSTER_CREATION_LOCKED ? <Divider /> : null}
    </NameContainer>
  );
}

export function Dropdown() {
  const { logout } = useSession();
  const { showModal } = useModal();

  function handleAccountSettingsClick() {
    showModal(<AccountSettingsModal hasErrors={""} />);
  }

  return (
    <Popover.Root>
      <SettingsContainer>
        <Settings />
      </SettingsContainer>
      <Popover.Portal>
        <DropdownContent align="start">
          <DropdownItem onClick={handleAccountSettingsClick}>Account Settings</DropdownItem>
          <DropdownItem onClick={logout}>Log Out</DropdownItem>
        </DropdownContent>
      </Popover.Portal>
    </Popover.Root>
  );
}
