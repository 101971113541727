import React, { FunctionComponent, useState } from "react";
import { Button } from "src/styles/buttons.styled";
import styled from "styled-components";
import { palette, pxToRem } from "../../styles/styleUtils";
import { useSession } from "../../hooks/useSession";
import { breakpoints } from "../../styles/styleUtils";
import { ModalBody, ModalHeader } from "../Modal/StandardModal";

const HeaderDescription = styled.p`
  text-align: center;
`;

const StyledShareGroupSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  border: 1px solid ${palette.primaryColor5};
  text-align: center;
  padding: 20px 23px 20px 23px;
  margin-bottom: 20px;
  width: 300px;
  min-height: 180px;

  > svg {
    width: 26px;
    height: 26px;
    path {
      fill: ${(props) => props.theme.primary};
    }
  }
  > h2 {
    font-size: 12px;
    font-weight: bold;
    line-height: 13px;
    letter-spacing: 0.18px;
    text-transform: uppercase;
    margin-top: 13px;
    margin-bottom: 10px;
  }
  > .title {
    font-weight: bold;
    line-height: 21px;
    margin: 5px 0 5px 0;
    font-size: ${pxToRem(16)};
  }
  > .description {
    font-size: 14px;
    line-height: 21px;
    margin: 5px 0 15px 0;
    font-size: ${pxToRem(14)};
    min-height: 43px;
  }
  > span.success {
    font-size: 14px;
    margin-top: 5px;
  }

  button {
    color: white;
  }
  a {
    color: white;
  }

  &:first-child {
    margin-right: 30px;
    @media (max-width: ${breakpoints.mobile}) {
      margin-right: 0px;
    }
  }
`;

const ShareGroupContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
  }
`;

interface ShareGroupModalProps {
  url: string;
  groupName?: string;
}

const ShareGroupModal: FunctionComponent<ShareGroupModalProps> = ({ url, groupName }) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const { user } = useSession();
  const handleCopy = async () => {
    navigator.clipboard.writeText(url).then(() => setCopySuccess(true));
  };

  return (
    <>
      <ModalHeader title="Share My Group">
        <HeaderDescription>
          Don&apos;t forget to invite your friends to play with you via email or a shared URL.
        </HeaderDescription>
      </ModalHeader>
      <ModalBody>
        <ShareGroupContainer>
          <StyledShareGroupSection>
            <p className="title">INVITE VIA EMAIL</p>
            <p className="description">Send the group invite via email</p>
            <a
              rel="noreferrer"
              target="_blank"
              style={{ textDecoration: "none", textTransform: "uppercase", color: "#ffffff !important" }}
              href={`mailto:?subject=PGA Championship Fantasy Invite to ${groupName} &body=Your friend ${user.first_name} wants you to join their PGA Championship Fantasy group, ${groupName}. Click the link to join the action:%0A%0a ${url}`}
            >
              <Button>Open Email</Button>
            </a>
          </StyledShareGroupSection>
          <StyledShareGroupSection>
            <p className="title">COPY GROUP LINK</p>
            <p className="description">Send this link directly to all of your friends</p>
            <Button onClick={handleCopy}>{copySuccess ? <span className="success">Copied!</span> : "Copy Link"}</Button>
          </StyledShareGroupSection>
        </ShareGroupContainer>
      </ModalBody>
    </>
  );
};

export default ShareGroupModal;
