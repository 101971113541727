import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { useModal } from "./index";
import TiebreakerModal from "../Tiebreaker/Tiebreaker";
import Close from "../Icons/Close";
import { breakpoints } from "../../styles/styleUtils";

const StyledCloseButton = styled(Close)`
  position: absolute;
  top: 17px;
  right: 20px;
  font-size: 25px;
  font-weight: bold;
  line-height: 18px;
  cursor: pointer;
  z-index: 1001;
  &:after {
    display: inline-block;
    content: "\\00d7";
  }
  @media (min-width: ${breakpoints.tablet}) and (min-height: 400px) {
    right: 30px;
    top: 20px;
    font-size: 34px;
    line-height: 23px;
    font-weight: normal;
  }
`;

interface PicksCloseButtonProps {
  hasPicks: boolean;
}

const handlePicksClose = (hasPicks, showModal) => {
  if (hasPicks) showModal(<TiebreakerModal />);
};

export const PicksCloseButton: FunctionComponent<PicksCloseButtonProps> = ({ hasPicks }) => {
  const { showModal } = useModal();
  return <StyledCloseButton onClick={() => handlePicksClose(hasPicks, showModal)} />;
};

const CloseButton: FunctionComponent = () => {
  const { dismissModal } = useModal();
  return <StyledCloseButton onClick={dismissModal} />;
};

export const WelcomeCloseButton: FunctionComponent = () => {
  const { dismissModal } = useModal();

  const handleCloseButton = () => {
    dismissModal();
    localStorage.setItem("welcomeModalClosed", "true");
  };

  return <StyledCloseButton onClick={handleCloseButton} />;
};

export default CloseButton;
