import React, { FunctionComponent } from "react";
import { palette, pxToRem } from "src/styles/styleUtils";
import styled from "styled-components";

const StyledSelectContainer = styled.label`
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  font-size: ${pxToRem(12)};
  text-align: left;

  > select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: #fff
      url("data:image/svg+xml;utf8,<svg width='14' height='14' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M43.7192 8L23.0348 36.9483C21.911 38.5211 22.275 40.7071 23.8477 41.8308V41.8308L46.5669 10.0348L43.7192 8Z' fill='black'/><path d='M3.62726 8L24.3116 36.9483C25.4354 38.5211 25.0715 40.7071 23.4987 41.8308V41.8308L0.779518 10.0348L3.62726 8Z' fill='black'/></svg>")
      no-repeat;
    background-position: right 13px top 50%;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    line-height: 20px;
    font-size: 14px;
    padding: 13px 12px 12px 12px;
    resize: none;

    &:placeholder {
      color: ${palette.supportingColor2};
    }
  }

  .error {
    color: #f00;
    padding-top: 5px;
    font-size: 12px;
  }
`;

interface InputContainerProps {
  label?: string | JSX.Element | null;
}

const SelectContainer: FunctionComponent<InputContainerProps> = ({ label, children }) => {
  return (
    <StyledSelectContainer>
      {label}
      {children}
    </StyledSelectContainer>
  );
};

export default SelectContainer;
