import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, ButtonGroup } from "src/styles/buttons.styled";
import InputContainer from "../inputs/InputContainer";
import { useModal } from "../Modal";
import { Group } from "./GroupsList";
import { ModalBody, ModalHeader } from "../Modal/StandardModal";
import useClientContext, { setCurrentRoster } from "src/hooks/useClientContext";
import ShareGroupModal from "./ShareGroupModal";
import useApiFetch from "src/hooks/useApiFetch";
import { useGroupsMutation } from "src/hooks/useEntryMutation";
import settings from "../../settings";
import { IGroupData } from "../../pages/groups/[...slug]";
import styled from "styled-components";
import { breakpoints } from "../../styles/styleUtils";
import { ErrorContainer, GenericFormError } from "../SignIn/SignIn.styled";
import Close from "../Icons/Close";
import useAnalytics from "../../hooks/useAnalytics";

const ContentsContainer = styled.div`
  @media (max-width: ${breakpoints.tablet}) {
    margin: 0 auto;
    width: 90%;
  }
`;

const StyledGenericFormError = styled(GenericFormError)`
  text-align: center;
  padding: 0;
`;

const StyledCloseButton = styled(Close)`
  position: absolute;
  top: 17px;
  right: 15px;
  font-size: 25px;
  font-weight: bold;
  line-height: 18px;
  cursor: pointer;
  z-index: 1001;
  &:after {
    display: inline-block;
    content: "\\00d7";
  }
  @media (min-width: ${breakpoints.tablet}) and (min-height: 400px) {
    right: 30px;
    top: 20px;
    font-size: 34px;
    line-height: 23px;
    font-weight: normal;
  }
`;

type CreateGroupInputs = {
  name: string;
  description: string;
};

export default function CreateGroupModal(props: {
  handleGroupJoin?: (groupsList: Group[]) => void;
  handleClearCategory?: () => void;
}) {
  const { handleGroupJoin, handleClearCategory } = props;
  const { showModal, modalContent, dismissModal } = useModal();
  const { fetchApiEndpoint } = useApiFetch();
  const [{ entry }, dispatch] = useClientContext();
  const [genericError, setGenericError] = useState<string>("");
  const { fireEvent, isWomens } = useAnalytics();
  const mutation = useGroupsMutation((data) => {
    return fetchApiEndpoint(`/api/v1/pgaoaroster/groups.json`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  });

  const fetchGroup = (id) => {
    return fetchApiEndpoint<IGroupData>(`/api/static-v1/pgaoaroster/groups/${id}/page/1.json`, {
      method: "GET",
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateGroupInputs>();
  const onSubmit: SubmitHandler<CreateGroupInputs> = async (values) => {
    mutation.mutate(values, {
      onSuccess: (data) => {
        fetchGroup(data.group_id).then((res) => {
          const isIframe = window?.location !== window?.parent.location;
          let appUrl = settings.APP_URL;
          const isProd = settings.APP_ENV === "production";
          if (isIframe) {
            if (isProd)
              appUrl = isWomens
                ? `https://www.kpmgwomenspgachampionship.com/fantasy/#`
                : `https://www.pgachampionship.com/fantasy/#`;
            else appUrl = `https://pgachampionship.uat.pga.golf.psdops.com/fantasy/#`;
          }
          const groupInfo = res.group_memberships.filter((group) => group?.entry_id === entry.entry_id);
          const groups = entry.group_memberships;
          groups.push({
            creator_id: data.creator_id,
            entry_count: data.entries_count,
            group_id: data.group_id,
            group_name: data.name,
            group_rank: groupInfo[0].group_rank,
            join_token: data.join_token,
          });
          if (handleGroupJoin) handleGroupJoin(groups);
          dispatch(setCurrentRoster(entry));
          showModal(
            <ShareGroupModal
              groupName={data.name}
              url={`${appUrl}/groups/${data.group_id}/groupJoinToken/${data.join_token}`}
            />,
          );
        });
      },
      onError: (error: Error, vars: any) => {
        const groupName = vars.name;
        const errorMessage = error.message == "is already taken" ? `${groupName} is already taken` : error.message;
        setGenericError(errorMessage);
      },
    });
  };

  useEffect(() => {
    if (modalContent) {
      fireEvent({ event: "Open", params: "Fantasy Create Group Modal" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalContent]);

  return (
    <ContentsContainer id="group-create-form">
      <ModalHeader hideClose title="Create a Group">
        <StyledCloseButton
          onClick={() => {
            handleClearCategory();
            dismissModal();
          }}
        />
        <p>
          Enter the name of your group along with a brief description. When your group is created you will then be able
          to invite friends to join.
        </p>
      </ModalHeader>
      <ModalBody style={{ paddingTop: 0 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ErrorContainer>
            {genericError !== "" && <StyledGenericFormError>{genericError}</StyledGenericFormError>}
          </ErrorContainer>
          <InputContainer style={{ display: "flex", flexDirection: "column" }}>
            <ErrorContainer>
              {errors.name?.type === "required" && <span className="error">Name is required.</span>}
              {errors.name?.type === "duplicate" && (
                <span className="error">This group name is already in use, please choose a different name.</span>
              )}
              {errors.name?.type === "minLength" && (
                <span className="error">Enter a longer name, at least 6 characters.</span>
              )}
            </ErrorContainer>
            <input
              style={{ width: "100%" }}
              type="text"
              placeholder="Name"
              {...register("name", { required: true, minLength: 6 })}
            />
          </InputContainer>
          <InputContainer style={{ display: "flex", flexDirection: "column" }}>
            <textarea
              placeholder="Description"
              style={{ height: 112, width: "100%" }}
              {...register("description")}
            ></textarea>
          </InputContainer>
          <ButtonGroup style={{ marginTop: 11 }}>
            <Button onClick={handleSubmit(onSubmit)}>Create My Group</Button>
          </ButtonGroup>
        </form>
      </ModalBody>
    </ContentsContainer>
  );
}
