function Close(props) {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>drop</title>
      <g id="roster-4-card-v2-odds" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.948245094">
        <g id="Landing-page---edit-group" transform="translate(-1207.000000, -210.000000)">
          <g id="drop" transform="translate(1207.384966, 210.592116)">
            <circle
              id="Oval"
              stroke="currentColor"
              strokeWidth="2"
              fill="#FFFFFF"
              cx="14.6924828"
              cy="14.6924828"
              r="13.6924828"
            ></circle>
            <path
              d="M16.1349656,6 L16.1348176,13.115 L23.2508176,13.115852 L23.2508176,15.884148 L16.1348176,15.884 L16.1349656,23 L13.3666696,23 L13.3658176,15.884 L6.2508176,15.884148 L6.2508176,13.115852 L13.3658176,13.115 L13.3666696,6 L16.1349656,6 Z"
              id="Combined-Shape"
              fill="currentColor"
              transform="translate(14.750818, 14.500000) rotate(-45.000000) translate(-14.750818, -14.500000) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Close;
