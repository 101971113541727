import React, { FunctionComponent } from "react";
import { breakpoints, palette, pxToRem } from "src/styles/styleUtils";
import styled from "styled-components";

const StyledInputContainer = styled.label`
  display: flex;
  justify-items: stretch;
  margin-bottom: 15px;
  flex-flow: column;
  text-align: left;
  font-size: ${pxToRem(12)};

  input,
  textarea,
  select {
    background: #fff;
    border-radius: 4px;
    border: 1px solid ${palette.primaryColor5};
    line-height: 20px;
    font-size: 14px;
    padding: 13px 12px 12px 12px;
    resize: none;

    &:placeholder {
      color: ${palette.supportingColor2};
    }
  }

  .zipcode {
    margin-right: 20px;
    @media (max-width: ${breakpoints.mobile}) {
      margin-right: 0;
    }
  }

  .error {
    color: #f00;
    padding-top: 3px;
    font-size: 12px;
  }
`;

interface InputContainerProps {
  label?: string | JSX.Element | null;
  style?: any;
  className?: string;
}

const InputContainer: FunctionComponent<InputContainerProps> = ({ label, children, style, className }) => {
  return (
    <StyledInputContainer className={className} style={style}>
      {label}
      {children}
    </StyledInputContainer>
  );
};

export default InputContainer;
