import React, { FunctionComponent, useState, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components";
import { Button, ButtonGroup } from "../../styles/buttons.styled";
import InputContainer from "../inputs/InputContainer";
import { StyledModalHeader } from "../Modal/StandardModal";
import { useModal } from "../Modal";
import CloseButton from "../Modal/CloseButton";
import SignInModal from "./SignInModal";
import EntryModal from "./EntryModal";
import { useSession } from "../../hooks/useSession";
import {
  GenericFormError,
  PromotionalInfoContainer,
  RemainingAddressContainer,
  SupportingQuestionsContainer,
  PromotionalInfoDescription,
  ErrorContainer,
} from "./SignIn.styled";
import SelectContainer from "../inputs/SelectContainer";
import { roundsPerYear, avgScore } from "./constants";
import { getGameState } from "src/utils/game";
import { useRosterQuery } from "src/hooks/api";
import { GAMESTATE } from "../../constants/interfaces/game";
import { breakpoints, pxToRem } from "../../styles/styleUtils";
import useAnalytics from "../../hooks/useAnalytics";

const StyledSignUpModal = styled.div`
  margin: 0 auto;
  text-align: center;
  padding: 55px 0;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: underline;
  }
`;

const StyledCheckbox = styled.label`
  font-size: 12px;
  width: 100%;

  input {
    margin-right: 17px;
  }
  p {
    margin: 0;
    font-size: 12px;
  }
  .error {
    color: #f00;
    font-size: 12px;
  }
`;

const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  overflow: hidden;
  padding: 3px;
  transform: translateY(-15px);

  .error {
    text-align: left;
  }

  .row {
    margin: 0 auto;
    display: flex;
    padding-top: 3px;

    @media screen and (max-width: ${breakpoints.mobile}) {
      flex-direction: column;
      width: 80%;
    }

    input {
      margin-right: 20px;
      @media screen and (max-width: ${breakpoints.mobile}) {
        margin-right: 0px;
      }
    }
  }
`;

const SignUpModalSectionHeader = styled(StyledModalHeader)`
  padding: 0;

  h1 {
    font-size: 32px;
    text-transform: none;
    margin-bottom: 15px;

    @media screen and (max-width: ${breakpoints.mobile}) {
      font-size: 24px;
      line-height: 26px;
    }
  }

  p {
    font-size: ${pxToRem(14)};
    max-width: 600px;
    margin: 0 30px 15px 30px;
  }

  span {
    display: block;
  }
`;

const TermsContainer = styled.div`
  text-align: center;
  margin: 0 25px;
`;

const OverAgeContainer = styled.div`
  display: flex;
  text-align: center;
  margin: 0 25px;
`;

const AgeCheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type SignUpInputs = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  accept_terms: boolean;
  over_21: boolean;
  over_18: boolean;
  zip?: number;
  country?: string;
  address?: string;
  state?: string;
  city?: string;
  generic?: string;
  birthdate?: string;
  rounds_of_golf?: number;
  average_score?: number;
  mindset?: string;
  handicap?: string;
  us_pga_marketing?: boolean;
  us_nbc_marketing?: boolean;
  europe_marketing?: boolean;
};

const GameLockedMessage = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;

const Optional = styled.span`
  font-size: ${pxToRem(10)};
  margin-left: 5px;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${pxToRem(5)};
`;

const SignUpModal: FunctionComponent = () => {
  const [genericError, setGenericError] = useState<string>("");
  const { dismissModal, showModal, modalContent } = useModal();
  const { registerSubmit } = useSession();
  const { data: game } = useRosterQuery();
  const gameState: string = getGameState(game);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SignUpInputs>();
  const { fireEvent, isWomens } = useAnalytics();

  function checkAge(dob) {
    // dates are all converted to date objects
    const my_dob = new Date(dob);
    const today = new Date();
    const max_dob = isWomens
      ? new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())
      : new Date(today.getFullYear() - 21, today.getMonth(), today.getDate());
    return max_dob.getTime() > my_dob.getTime();
  }

  useEffect(() => {
    if (modalContent) {
      fireEvent({ event: "Open", params: "Fantasy: Registration Modal" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalContent]);

  const onSubmit: SubmitHandler<SignUpInputs> = (data) => {
    if (gameState != GAMESTATE.ROSTER_CREATION_LOCKED) {
      registerSubmit(data)
        .then((res) => {
          fireEvent({ event: "Sign_up", params: "Sign up: Success" });
          if (res.pgaoaroster_entries.length === 0 && gameState === GAMESTATE.ROSTER_CREATION_OPEN)
            showModal(<EntryModal />);
          else if (res.pgaoaroster_entries.length > 0 || gameState === GAMESTATE.ROSTER_CREATION_LOCKED) dismissModal();
        })
        .catch((e) => {
          fireEvent({ event: "Sign_up", params: "Sign up: error" });
          setGenericError(e);
        });
    } else {
      setGenericError("Picks have locked");
      setError("generic", { type: "generic" }, { shouldFocus: true });
    }
  };

  return (
    <StyledSignUpModal>
      <SignUpModalSectionHeader>
        <CloseButton />
        <h1>Sign up to Play</h1>
        <p>
          Create an account and play {isWomens ? "KPMG Women's" : ""} PGA Championship Fantasy! Join the action and
          create your own custom groups to challenge friends and family and compete to win the Grand Prize.
        </p>
        <p style={{ marginBottom: 5 }}>
          Already have an account?{" "}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              showModal(<SignInModal origin="Signup" />);
            }}
          >
            Sign In
          </a>
        </p>
      </SignUpModalSectionHeader>
      <div>
        <ErrorContainer>{genericError && <GenericFormError>{genericError}</GenericFormError>}</ErrorContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SignUpContainer>
            <div className="row">
              <InputContainer style={{ flexDirection: "column", position: "relative", marginBottom: 7 }}>
                <ErrorContainer>
                  {errors.first_name?.type === "required" && <span className="error">First name is required.</span>}
                </ErrorContainer>
                <input type="text" placeholder="First Name" {...register("first_name", { required: true })} />
              </InputContainer>
              <InputContainer style={{ flexDirection: "column", position: "relative", marginBottom: 7 }}>
                <ErrorContainer>
                  {errors.last_name?.type === "required" && <span className="error">Last name is required.</span>}
                </ErrorContainer>
                <input type="text" placeholder="Last Name" {...register("last_name", { required: true })} />
              </InputContainer>
            </div>
            <div className="row">
              <InputContainer style={{ flexDirection: "column", position: "relative", marginBottom: 7 }}>
                <ErrorContainer>
                  {errors.email?.type === "required" && <span className="error">Email is required.</span>}
                  {errors.email?.type === "minLength" && (
                    <span className="error">Enter a longer email address, at least 6 characters.</span>
                  )}
                  {errors.email?.type === "pattern" && <span className="error">Enter a valid email address.</span>}
                </ErrorContainer>
                <input
                  type="text"
                  placeholder="Email Address"
                  {...register("email", {
                    required: true,
                    minLength: 6,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Enter a valid email address.",
                    },
                  })}
                />
              </InputContainer>
              <InputContainer style={{ flexDirection: "column", position: "relative", marginBottom: 7 }}>
                <ErrorContainer>
                  {errors.password?.type === "required" && <span className="error">Password is required.</span>}
                  {errors.password?.type === "invalid" && (
                    <span className="error">Email or password is not correct.</span>
                  )}
                </ErrorContainer>
                <input type="password" placeholder="Password" {...register("password", { required: true })} />
              </InputContainer>
            </div>
            <div className="row">
              <InputContainer style={{ position: "relative" }}>
                Date of Birth
                <input
                  style={{ height: "47px" }}
                  name="birthdate"
                  max={new Date().toISOString().split("T")[0]}
                  type="date"
                  {...register("birthdate", {
                    max: new Date().toISOString().split("T")[0],
                    required: true,
                    validate: checkAge,
                  })}
                />
                <ErrorContainer>
                  {errors.birthdate?.type === "max" ||
                    (errors.birthdate?.type === "required" && <span className="error">Invalid birthdate</span>)}
                  {errors.birthdate?.type === "validate" && (
                    <span className="error">Must be {isWomens ? "18" : "21"} years of age</span>
                  )}
                </ErrorContainer>
              </InputContainer>
            </div>
          </SignUpContainer>
          <PromotionalInfoContainer>
            <PromotionalInfoDescription>
              {isWomens
                ? ""
                : "Complete your profile here and receive 10% off your order from the Official PGA Championship online store."}
            </PromotionalInfoDescription>
            <RemainingAddressContainer>
              <InputContainer>
                <LabelContainer>
                  Zip Code <Optional>(optional)</Optional>
                </LabelContainer>

                <input
                  className="zipcode"
                  type="text"
                  placeholder="Zip Code"
                  {...register("zip", { required: false })}
                />
              </InputContainer>
              <SelectContainer>
                <LabelContainer>
                  Rounds of golf played per year <Optional>(optional)</Optional>
                </LabelContainer>
                <select {...register("rounds_of_golf", { required: false })}>
                  <option value="">Select</option>
                  {roundsPerYear.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </SelectContainer>
            </RemainingAddressContainer>
            <SupportingQuestionsContainer>
              <SelectContainer>
                <LabelContainer>
                  Average score for 18 holes <Optional>(optional)</Optional>
                </LabelContainer>
                <select {...register("average_score", { required: false })}>
                  <option value="">Select</option>
                  {avgScore.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </SelectContainer>
              <SelectContainer>
                <LabelContainer>
                  What is Your Handicap? <Optional>(optional)</Optional>
                </LabelContainer>
                <select {...register("handicap", { required: false })}>
                  <option value="">Select</option>
                  <option value="5 or lower">5 or lower</option>
                  <option value="6-15">6-15</option>
                  <option value="16-25">16-25</option>
                  <option value="26 or higher">26 or higher</option>
                  <option value="I don't have a handicap">I don&apos;t have a handicap</option>
                </select>
              </SelectContainer>
              <SelectContainer>
                <LabelContainer>
                  What is your mindset when you play golf? <Optional>(optional)</Optional>
                </LabelContainer>
                <select {...register("mindset", { required: false })}>
                  <option value="">Select</option>
                  <option value="I want to win">I want to win</option>
                  <option value="I want to socialize">I want to socialize</option>
                  <option value="I want to relax and refocus">I want to relax and refocus</option>
                </select>
              </SelectContainer>
            </SupportingQuestionsContainer>
          </PromotionalInfoContainer>
          <TermsContainer>
            <StyledCheckbox>
              <ErrorContainer>
                {errors.accept_terms?.type === "required" && (
                  <span style={{ display: "block" }} className="error">
                    You must accept these terms before proceeding
                  </span>
                )}
              </ErrorContainer>
              <input type="checkbox" {...register("accept_terms", { required: true })} />
              By checking this box, you agree to PGA of America&apos;s{" "}
              <a href="https://www.pga.com/pga-of-america/privacy-policy" rel="noreferrer" target="_blank">
                Privacy Policy
              </a>
            </StyledCheckbox>
          </TermsContainer>
          <OverAgeContainer>
            <StyledCheckbox>
              <ErrorContainer>
                {errors.over_18?.type === "required" && isWomens && (
                  <span style={{ display: "block" }} className="error">
                    You must accept that you are over the age of 18
                  </span>
                )}
                {errors.over_21?.type === "required" && (
                  <span style={{ display: "block" }} className="error">
                    You must accept that you are over the age of 21
                  </span>
                )}
              </ErrorContainer>
              {isWomens ? (
                <AgeCheckboxContainer>
                  <input type="checkbox" {...register("over_18", { required: true })} />
                  <div>By checking this box, you agree that you are over the age of 18</div>
                </AgeCheckboxContainer>
              ) : (
                <AgeCheckboxContainer>
                  <input type="checkbox" {...register("over_21", { required: true })} />
                  <div>By checking this box, you agree that you are over the age of 21</div>
                </AgeCheckboxContainer>
              )}
            </StyledCheckbox>
          </OverAgeContainer>
          <GameLockedMessage>
            {(errors.generic?.type === "generic" || gameState === GAMESTATE.ROSTER_CREATION_LOCKED) && (
              <div style={{ textAlign: "center", color: "red", marginBottom: 10 }} className="error">
                The PGA Championship tournament has started and rosters are locked. <br /> Go to{" "}
                <a href="https://www.pgachampionship.com/" target="_blank" rel="noopener noreferrer">
                  pgachampionship.com
                </a>{" "}
                to view player scores or{" "}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    showModal(<SignInModal origin="Signup" />);
                  }}
                >
                  Sign In
                </a>
              </div>
            )}
          </GameLockedMessage>
        </form>
        {gameState != GAMESTATE.ROSTER_CREATION_LOCKED && (
          <ButtonGroup style={{ flexFlow: "column", alignItems: "center" }}>
            <Button onClick={handleSubmit(onSubmit)}>Sign Up</Button>
          </ButtonGroup>
        )}
      </div>
    </StyledSignUpModal>
  );
};

export default SignUpModal;
