
export const states = [
  {
    "name": "Alabama",
    "abbreviation": "AL"
  },
  {
    "name": "Alaska",
    "abbreviation": "AK"
  },
  {
    "name": "Arizona",
    "abbreviation": "AZ"
  },
  {
    "name": "Arkansas",
    "abbreviation": "AR"
  },
  {
    "name": "California",
    "abbreviation": "CA"
  },
  {
    "name": "Colorado",
    "abbreviation": "CO"
  },
  {
    "name": "Connecticut",
    "abbreviation": "CT"
  },
  {
    "name": "Delaware",
    "abbreviation": "DE"
  },
  {
    "name": "District Of Columbia",
    "abbreviation": "DC"
  },
  {
    "name": "Florida",
    "abbreviation": "FL"
  },
  {
    "name": "Georgia",
    "abbreviation": "GA"
  },
  {
    "name": "Hawaii",
    "abbreviation": "HI"
  },
  {
    "name": "Idaho",
    "abbreviation": "ID"
  },
  {
    "name": "Illinois",
    "abbreviation": "IL"
  },
  {
    "name": "Indiana",
    "abbreviation": "IN"
  },
  {
    "name": "Iowa",
    "abbreviation": "IA"
  },
  {
    "name": "Kansas",
    "abbreviation": "KS"
  },
  {
    "name": "Kentucky",
    "abbreviation": "KY"
  },
  {
    "name": "Louisiana",
    "abbreviation": "LA"
  },
  {
    "name": "Maine",
    "abbreviation": "ME"
  },
  {
    "name": "Maryland",
    "abbreviation": "MD"
  },
  {
    "name": "Massachusetts",
    "abbreviation": "MA"
  },
  {
    "name": "Michigan",
    "abbreviation": "MI"
  },
  {
    "name": "Minnesota",
    "abbreviation": "MN"
  },
  {
    "name": "Mississippi",
    "abbreviation": "MS"
  },
  {
    "name": "Missouri",
    "abbreviation": "MO"
  },
  {
    "name": "Montana",
    "abbreviation": "MT"
  },
  {
    "name": "Nebraska",
    "abbreviation": "NE"
  },
  {
    "name": "Nevada",
    "abbreviation": "NV"
  },
  {
    "name": "New Hampshire",
    "abbreviation": "NH"
  },
  {
    "name": "New Jersey",
    "abbreviation": "NJ"
  },
  {
    "name": "New Mexico",
    "abbreviation": "NM"
  },
  {
    "name": "New York",
    "abbreviation": "NY"
  },
  {
    "name": "North Carolina",
    "abbreviation": "NC"
  },
  {
    "name": "North Dakota",
    "abbreviation": "ND"
  },
  {
    "name": "Ohio",
    "abbreviation": "OH"
  },
  {
    "name": "Oklahoma",
    "abbreviation": "OK"
  },
  {
    "name": "Oregon",
    "abbreviation": "OR"
  },
  {
    "name": "Pennsylvania",
    "abbreviation": "PA"
  },
  {
    "name": "Puerto Rico",
    "abbreviation": "PR"
  },
  {
    "name": "Rhode Island",
    "abbreviation": "RI"
  },
  {
    "name": "South Carolina",
    "abbreviation": "SC"
  },
  {
    "name": "South Dakota",
    "abbreviation": "SD"
  },
  {
    "name": "Tennessee",
    "abbreviation": "TN"
  },
  {
    "name": "Texas",
    "abbreviation": "TX"
  },
  {
    "name": "Utah",
    "abbreviation": "UT"
  },
  {
    "name": "Vermont",
    "abbreviation": "VT"
  },
  {
    "name": "Virginia",
    "abbreviation": "VA"
  },
  {
    "name": "Washington",
    "abbreviation": "WA"
  },
  {
    "name": "West Virginia",
    "abbreviation": "WV"
  },
  {
    "name": "Wisconsin",
    "abbreviation": "WI"
  },
  {
    "name": "Wyoming",
    "abbreviation": "WY"
  }
]

export const countries = [
  { 'name': 'Albania', abbreviation: 'AL' },
  { 'name': 'Andorra', abbreviation: 'AD' },
  { 'name': 'Austria', abbreviation: 'AT' },
  { 'name': 'Belarus', abbreviation: 'BY' },
  { 'name': 'Belgium', abbreviation: 'BE' },
  { 'name': 'Bosnia and Herzegovina', abbreviation: 'BA' },
  { 'name': 'Bulgaria', abbreviation: 'BG' },
  { 'name': 'Croatia', abbreviation: 'HR' },
  { 'name': 'Czech Republic', abbreviation: 'CZ' },
  { 'name': 'Denmark', abbreviation: 'DK' },
  { 'name': 'Estonia', abbreviation: 'EE' },
  { 'name': 'Finland', abbreviation: 'FI' },
  { 'name': 'France', abbreviation: 'FR' },
  { 'name': 'Germany', abbreviation: 'DE' },
  { 'name': 'Greece', abbreviation: 'EL' },
  { 'name': 'Holy See', abbreviation: 'VA' },
  { 'name': 'Hungary', abbreviation: 'HU' },
  { 'name': 'Iceland', abbreviation: 'IS' },
  { 'name': 'Ireland', abbreviation: 'IE' },
  { 'name': 'Italy', abbreviation: 'IT' },
  { 'name': 'Latvia', abbreviation: 'LV' },
  { 'name': 'Liechtenstein', abbreviation: 'LI' },
  { 'name': 'Lithuania', abbreviation: 'LT' },
  { 'name': 'Luxembourg', abbreviation: 'LU' },
  { 'name': 'Malta', abbreviation: 'MT' },
  { 'name': 'Moldova', abbreviation: 'MD' },
  { 'name': 'Monaco', abbreviation: 'MC' },
  { 'name': 'Montenegro', abbreviation: 'ME' },
  { 'name': 'Netherlands', abbreviation: 'NL' },
  { 'name': 'North Macedonia', abbreviation: 'MK' },
  { 'name': 'Norway', abbreviation: 'NO' },
  { 'name': 'Poland', abbreviation: 'PL' },
  { 'name': 'Portugal', abbreviation: 'PT' },
  { 'name': 'Romania', abbreviation: 'RO' },
  { 'name': 'Russia', abbreviation: 'RU' },
  { 'name': 'San Marino', abbreviation: 'SM' },
  { 'name': 'Serbia', abbreviation: 'RS' },
  { 'name': 'Slovakia', abbreviation: 'SK' },
  { 'name': 'Slovenia', abbreviation: 'SI' },
  { 'name': 'Spain', abbreviation: 'ES' },
  { 'name': 'Sweden', abbreviation: 'SE' },
  { 'name': 'Switzerland', abbreviation: 'CH' },
  { 'name': 'Ukraine', abbreviation: 'UA' },
  { 'name': 'United Kingdom', abbreviation: 'UK' },
  { 'name': 'United States', abbreviation: 'US' },
]

export const roundsPerYear = ['0-5', '6-10', '11-20', '21+']

export const avgScore = ["Below 70", "70s", "80s", "90s", "100+", "Don't Play"]
