import { useEffect } from "react";
import styled from "styled-components";
// import { useModal } from "src/components/Modal"
import { Button, SecondaryButton } from "src/styles/buttons.styled";
import { useModal } from "src/components/Modal";
import { breakpoints } from "src/styles/styleUtils";
import CreateGroupModal from "../../Groups/CreateGroupModal";
import Close from "../../Icons/Close";
import useAnalytics from "../../../hooks/useAnalytics";

const RosterCompleteModalWrapper = styled.div`
  overflow: scroll;

  @media screen and (max-width: ${breakpoints.tablet}) {
    margin-top: 30px;
  }
`;

const ModalHeader = styled.div`
  text-align: center;

  &h1 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    height: 20px;
    letter-spacing: 0.27px;
    text-transform: uppercase;
  }

  @media screen and (max-width: ${breakpoints.tablet}) {
    margin-top: 30px;
  }
`;

const StyledText = styled.p`
  color: rgb(21, 21, 21);
  font-size: 14px;
  font-weight: normal;
  max-width: 500px;
  margin-bottom: 30px;
  letter-spacing: 0px;
  line-height: 21px;
  text-align: center;
  width: 314px;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    width: auto;
  }
`;

const StyledCloseButton = styled(Close)`
  position: absolute;
  top: 17px;
  right: 15px;
  font-size: 25px;
  font-weight: bold;
  line-height: 18px;
  cursor: pointer;
  z-index: 1001;
  &:after {
    display: inline-block;
    content: "\\00d7";
  }
  @media (min-width: ${breakpoints.tablet}) and (min-height: 400px) {
    right: 30px;
    top: 20px;
    font-size: 34px;
    line-height: 23px;
    font-weight: normal;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;

  > button:not(:last-of-type) {
    margin-right: 15px;
  }

  @media screen and (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
    gap: 20px;
  }
`;
const RosterCompleteModal = (props: { handleClearCategory: () => void }) => {
  const { handleClearCategory } = props;
  const { dismissModal, showModal, modalContent } = useModal();
  const { fireEvent, isWomens } = useAnalytics();

  useEffect(() => {
    if (modalContent) {
      fireEvent({ event: "Open", params: "Fantasy Roster Complete" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalContent]);

  return (
    <RosterCompleteModalWrapper>
      <ModalHeader>
        <StyledCloseButton
          onClick={() => {
            handleClearCategory();
            dismissModal();
          }}
        />
        <h1>
          <div>Congratulations!</div>
          <div>Your roster is complete</div>
        </h1>
      </ModalHeader>
      <ModalContent>
        <StyledText>
          Make the best out of your {isWomens ? "KPMG Women’s" : "PGA"} Championship Fantasy experience by creating a
          group and inviting your friends and family to play the game!
        </StyledText>
        <ButtonContainer>
          <Button onClick={() => showModal(<CreateGroupModal handleClearCategory={handleClearCategory} />)}>
            Create a group
          </Button>

          <SecondaryButton
            onClick={() => {
              handleClearCategory();
              dismissModal();
            }}
          >
            Back to Roster
          </SecondaryButton>
        </ButtonContainer>
      </ModalContent>
    </RosterCompleteModalWrapper>
  );
};

export default RosterCompleteModal;
